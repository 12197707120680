import SimpleIntro from "../components/General/SimpleIntro";
import VolunteeringBannerRight from "../components/General/VolunteeringBannerRightImg";
import Optional from "../components/AboutUs/Optional";
import FlightNumbers from "../components/AboutUs/FlightNumbers";
import PhotoGallery from "../components/AboutUs/PhotoGallery";

const AboutUs = () => {
  return (
    <section>
      <SimpleIntro urlApi="about/simpleIntro" />

      <VolunteeringBannerRight urlApi="about/volunteeringBannerRightImg" />

      <Optional />

      <FlightNumbers />

      <PhotoGallery />
    </section>
  );
};

export default AboutUs;
