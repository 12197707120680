import React, { useEffect, useState } from "react";
import SimpleCard from "./SimpleCard";

const SimpleCardsList = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://elgalatours-api.herokuapp.com/${props.urlApi}`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div className="container" style={{ margin: "7rem auto" }}>
      <div className="row">
        {items.map((item) => (
          <div key={item.id} className="col-lg-3 col-md-6 col-sm-12">
            <SimpleCard
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimpleCardsList;
