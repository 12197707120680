import React, { useEffect, useState } from "react";

const SimpleIntro = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://elgalatours-api.herokuapp.com/${props.urlApi}`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div>
      {items.map((item) => (
        <div
          key={item.id}
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(${item.img})`,
            height: "85vh",
            position: "relative",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            className="text-center my-3"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <h1 className="text-white fw-bolder">{item.title}</h1>
            <h1 id="title-quran" className="text-white fw-bolder">
              {item.subtitle}
            </h1>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SimpleIntro;
