import React, { useEffect, useState } from "react";
import Btn from "./Btn";

const VolunteeringBannerLeft = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://elgalatours-api.herokuapp.com/${props.urlApi}`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div className="container" style={{ margin: "7rem auto" }}>
      {items.map((item) => (
        <div key={item.id} className="row">
          <div className="col-lg-6 col-sm-12">
            <img
              src={item.img}
              className="card-img-top rounded"
              alt="Volunteering Banner Img"
            />
          </div>
          <div className="col-lg-6 col-sm-12 my-5 text-end">
            <p className="subtitle">{item.subtitle}</p>
            <h2 className="title mb-4">{item.title}</h2>
            <p className="subtitle mb-4">
              {item.topSubtitle}
              <br />
              <br />
              {item.underSubtitle}
            </p>
            <div style={{ display: `${item.btn}` }}>
              <Btn
                url={item.url}
                title={item.titleBtn}
                iconLeft={item.iconBtn}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VolunteeringBannerLeft;
