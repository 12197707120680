import AdvancedText from "../General/AdvancedText";
import AdvancesCardList from "../General/AdvancesCardList";

const Services = () => {
  return (
    <div className="container text-center" style={{ margin: "7rem auto" }}>
      <AdvancedText urlApi="home/servicesAdvancedText" />

      <AdvancesCardList urlApi="home/servicesList" />
    </div>
  );
};

export default Services;
