import React, { useEffect, useState } from "react";

const SimpleText = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://elgalatours-api.herokuapp.com/${props.urlApi}`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div className="container" style={{ margin: "7rem auto" }}>
      {items.map((item) => (
        <div key={item.id} className="text-end my-4">
          <h3 className="title">
            {item.title}
            <i className="bi bi-asterisk title ms-3"></i>
          </h3>
          <h4 className="subtitle">{item.subtitle}</h4>
        </div>
      ))}
    </div>
  );
};

export default SimpleText;
