import { Link } from "react-router-dom";
import Btn from "../General/Btn";

const Header = () => {
  return (
    <header id="header" className="navbar navbar-expand-lg navbar-light py-3">
      <nav className="container">
        <Link
          className="navbar-brand fs-2 mb-2 d-flex align-items-center"
          to="/"
        >
          <img
            src="https://res.cloudinary.com/dirbnpgsp/image/upload/v1653479167/Elgala%20Tours/logo_ofygpf.png"
            alt="logo"
            className="me-3"
            style={{
              width: "9%",
            }}
          />
          <h2 id="logo-name" className="fw-bolder mb-0">
            Elgala Tours
          </h2>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-end nav-items"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav d-flex align-items-end">
            <li className="nav-item">
              <Link className="nav-link mx-2" to="/about">
                <h5 className="title">معلومات عنا</h5>
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link className="nav-link" to="/why">
                <h5 className="title">لماذا نحن</h5>
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link className="nav-link" to="/services">
                <h5 className="title">خدماتنا</h5>
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link className="nav-link" to="/testimonials">
                <h5 className="title">اراء العملاء</h5>
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Btn url="/contact" title="تواصل معنا" />
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
