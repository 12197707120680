import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-dark text-white">
      <div style={{ margin: "0 10%", padding: "5rem 0 3.5rem 0" }}>
        <div className="row" style={{ minHeight: "20rem" }}>
          <div className="col-xl-2 col-md-6 col-sm-12 mb-4 text-end">
            <h4 className="mb-4">التواصل الاجتماعي</h4>
            <div>
              <a
                href="https://www.youtube.com/channel/UCnEcOS4E60r0o6VkaUzwV2Q?view_as=subscriber"
                target="_blank"
              >
                <i className="bi bi-youtube ms-4 fs-5"></i>
              </a>
              <a href="https://twitter.com/elgala13" target="_blank">
                <i className="bi bi-twitter ms-4 fs-5"></i>
              </a>
              <a href="https://www.instagram.com/ElgalaTours/" target="_blank">
                <i className="bi bi-instagram ms-4 fs-5"></i>
              </a>
              <a href="https://www.facebook.com/Elgala2333" target="_blank">
                <i className="bi bi-facebook ms-4 fs-5"></i>
              </a>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 mb-4 text-end">
            <h4 className="mb-4">اتصل بنا</h4>
            <div className="mb-3">
              <span className="fw-light ms-2">هاتف:</span>
              <a href="tel:01156000980">0980-600-0115</a> ,{" "}
              <a href="tel:01228020201">0201-802-0122</a>
            </div>
            <div className="mb-3">
              <span className="fw-light ms-2">هاتف ارضي:</span>
              <a href="tel:35703820">35703820</a> ,{" "}
              <a href="tel:35703822">35703822</a>
              <br />
              <a href="tel:22604100">22604100</a> ,{" "}
              <a href="tel:22604002">22604002</a>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 mb-4 text-end">
            <h4 className="mb-4">التذاكر والتأشيرات</h4>
            <div className="row">
              <div className="col-md-6 col-ms-12">
                <Link to="/services/touristTransportation" className="p-0">
                  <h6 className="mb-3 fw-light">النقل السياحي</h6>
                </Link>
                <Link to="/services/domesticTourism" className="p-0">
                  <h6 className="mb-3 fw-light">السياحة الداخلية</h6>
                </Link>
                <Link to="/services/foreignTourism" className="p-0">
                  <h6 className="mb-3 fw-light">السياحة الخارجية</h6>
                </Link>
                <Link to="/services/medicalTourism" className="p-0">
                  <h6 className="mb-3 fw-light">السياحة العلاجية</h6>
                </Link>
                <Link to="/services/honeymoonTrips" className="p-0">
                  <h6 className="mb-3 fw-light">رحلات شهر العسل</h6>
                </Link>
              </div>
              <div className="col-md-6 col-ms-12">
                <Link to="/services/pilgrimage" className="p-0">
                  <h6 className="mb-3 fw-light">الحج</h6>
                </Link>
                <Link to="/services/umrah" className="p-0">
                  <h6 className="mb-3 fw-light">العمرة</h6>
                </Link>
                <Link to="/services/visas" className="p-0">
                  <h6 className="mb-3 fw-light">التأشيرات</h6>
                </Link>
                <Link to="/services/hotelBooking" className="p-0">
                  <h6 className="mb-3 fw-light">حجز فنادق</h6>
                </Link>
                <Link to="/services/bookingFlight" className="p-0">
                  <h6 className="mb-3 fw-light">حجز الطيران</h6>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-sm-12 mb-4 text-end">
            <h4 className="mb-4">إعلم اكثر</h4>
            <Link to="/about" className="p-0">
              <h6 className="mb-3 fw-light">معلومات عنا</h6>
            </Link>
            <Link to="/why" className="p-0">
              <h6 className="mb-3 fw-light">لماذا نحن</h6>
            </Link>
            <Link to="/services" className="p-0">
              <h6 className="mb-3 fw-light">خدماتنا</h6>
            </Link>
            <Link to="/testimonials" className="p-0">
              <h6 className="mb-3 fw-light">اراء العملاء</h6>
            </Link>
            <Link to="/jobs" className=" p-0">
              <h6 className="mb-3 fw-light">وظائف</h6>
            </Link>
            <Link to="/contact" className="p-0">
              <h6 className="mb-3 fw-light">تواصل معنا</h6>
            </Link>
          </div>
          <div className="col-xl-2 col-md-6 col-sm-12 my-4">
            <Link
              to="/"
              className="d-flex align-items-center justify-content-end p-0"
            >
              <h3 id="logo-name" className="mb-0">
                Elgala Tours
              </h3>
              <img
                src="https://res.cloudinary.com/dirbnpgsp/image/upload/v1653479167/Elgala%20Tours/logo_ofygpf.png"
                alt="logo"
                className="ms-4"
                style={{ width: "50px", height: "50px" }}
              />
            </Link>
          </div>
        </div>

        <div className="text-center border-top">
          <h5 className="fw-light mt-4">
            © 2022 <span className="title">Elgala Tours</span> كل الحقوق محفوظة
            لي
          </h5>
          <h6>
            Site by {""}
            <a
              href="https://abd-elrahman-be-portfolio.herokuapp.com"
              target="_blank"
            >
              <span className="title">Launchy</span>
            </a>
          </h6>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
