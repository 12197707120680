import React, { useEffect, useState } from "react";
import Btn from "./Btn";

const AdvancedIntro = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://elgalatours-api.herokuapp.com/${props.urlApi}`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div>
      {items.map((item) => (
        <div
          key={item.id}
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('${item.img}')`,
            height: "70vh",
            position: "relative",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            className="text-center my-3"
            style={{
              maxWidth: "700px",
              minWidth: "300px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "2",
            }}
          >
            <h2 className="title mb-4" style={{ fontSize: "2.5rem" }}>
              {item.title}
            </h2>
            <h4 className="text-light fw-light mb-5">{item.subtitle}</h4>
            <div style={{ display: `${item.btnLink}` }}>
              <a href={item.link} target="_blank" className="btn">
                <i className={item.iconLink}></i>
                {item.titleLink}
              </a>
            </div>
            <div style={{ display: `${item.btn}` }}>
              <Btn url={item.url} title={item.titleBtn} iconLeft={item.icon} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdvancedIntro;
