import { Link } from "react-router-dom";

const AdvancedCard = (props) => {
  return (
    <div className="my-3">
      <Link to={props.url}>
        <div className="advanced-card">
          <img src={props.img} alt="service img" className="rounded" />
          <div className="info">
            <h3>{props.title}</h3>
            <p>{props.subtitle}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AdvancedCard;
