import React, { useEffect, useState } from "react";
import AdvancedText from "../General/AdvancedText";

const Optional = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://elgalatours-api.herokuapp.com/about/optional")
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div className="container text-center" style={{ margin: "7rem auto" }}>
      <AdvancedText urlApi="about/optionalAdvancedText" />

      <div className="row">
        {items.map((item) => (
          <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 my-3">
            <div style={{ margin: "0 20%" }}>
              <i
                className={`${item.icon} mt-2 mb-4`}
                style={{
                  fontSize: "2.5rem",
                  color: "var(--brand_secondary)",
                }}
              ></i>
              <h3 className="title">{item.title}</h3>
              <p className="subtitle">{item.subtitle} </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Optional;
