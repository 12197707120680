import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SpecialVolunteeringBannerRightImg = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://elgalatours-api.herokuapp.com/${props.urlApi}`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div style={{ background: "var(--text_third)", margin: "7rem auto" }}>
      <div
        className="container"
        style={{ paddingTop: "7rem", paddingBottom: "7rem" }}
      >
        {items.map((item) => (
          <div key={item.id} className="row">
            <div className="col-lg-6 col-sm-12 my-5 text-light text-end">
              <p>{item.subtitle}</p>
              <h2 className="mb-4">{item.title}</h2>
              <p className="mb-4">
                {item.topSubtitle}
                <br />
                <br />
                {item.underSubtitle}
              </p>
              <div style={{ display: `${item.btn}` }}>
                <Link to={item.url}>
                  <h6 className="btn btn-light">
                    <i className="bi bi-arrow-left"></i>
                    {item.titleBtn}
                  </h6>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <img
                src={item.img}
                className="card-img-top rounded"
                alt="Volunteering Banner Img"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialVolunteeringBannerRightImg;
