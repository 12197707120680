import SimpleIntro from "../../components/General/SimpleIntro";
import AdvancedText from "../../components/General/AdvancedText";
import TablePilgrimageTripsSchedule from "../../components/Tables/TablePilgrimageTripsSchedule";
import AdvancedIntro from "../../components/General/AdvancedIntro";

const Pilgrimage = () => {
  return (
    <section>
      <SimpleIntro urlApi="services/pilgrimage/simpleIntro" />

      <div className="container text-center">
        <div style={{ margin: "7rem auto" }}>
          <AdvancedText urlApi="services/pilgrimage/advancedText" />

          <div style={{ overflowX: "scroll", margin: "5rem auto" }}>
            <table className="table caption-top text-end">
              <caption className="text-end">
                <h2 className="title">رحلات حج طيران و بري</h2>
              </caption>
              <thead>
                <tr>
                  <th scope="col">بري</th>
                  <th scope="col">طيران</th>
                  <th scope="col">الموقع</th>
                  <th scope="col">مستوة</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <TablePilgrimageTripsSchedule urlApi="services/pilgrimage/tripsSchedule" />
            </table>
          </div>
        </div>
      </div>

      <AdvancedIntro urlApi="services/pilgrimage/advancedIntro" />
    </section>
  );
};

export default Pilgrimage;
