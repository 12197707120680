import SimpleIntro from "../../components/General/SimpleIntro";
import AdvancedText from "../../components/General/AdvancedText";
import TableUmrahTripsSchedule from "../../components/Tables/TableUmrahTripsSchedule";
import AdvancedIntro from "../../components/General/AdvancedIntro";

const Umrah = () => {
  return (
    <section>
      <SimpleIntro urlApi="services/umrah/simpleIntro" />

      <div className="container text-center">
        <div style={{ margin: "7rem auto" }}>
          <AdvancedText urlApi="services/umrah/advancedText" />

          <div style={{ overflowX: "scroll", margin: "5rem auto" }}>
            <table className="table caption-top text-end">
              <caption className="text-end">
                <h2 className="title">رحلات مولد النبوي الشريف</h2>
              </caption>
              <thead>
                <tr>
                  <th scope="col">بحر</th>
                  <th scope="col">بري</th>
                  <th scope="col">طيران</th>
                  <th scope="col">عدد الليالي</th>
                  <th scope="col">فنادق المدينة</th>
                  <th scope="col">المدينة</th>
                  <th scope="col">فنادق مكة</th>
                  <th scope="col">مكة</th>
                  <th scope="col">مستوة</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <TableUmrahTripsSchedule urlApi="services/umrah/tripsScheduleOne" />
            </table>
          </div>
          <div style={{ overflowX: "scroll", margin: "5rem auto" }}>
            <table className="table caption-top text-end">
              <caption className="text-end">
                <h2 className="title">رحلات شهر رجب</h2>
              </caption>
              <thead>
                <tr>
                  <th scope="col">بحر</th>
                  <th scope="col">بري</th>
                  <th scope="col">طيران</th>
                  <th scope="col">عدد الليالي</th>
                  <th scope="col">فنادق المدينة</th>
                  <th scope="col">المدينة</th>
                  <th scope="col">فنادق مكة</th>
                  <th scope="col">مكة</th>
                  <th scope="col">مستوة</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <TableUmrahTripsSchedule urlApi="services/umrah/tripsScheduleTwo" />
            </table>
          </div>
          <div style={{ overflowX: "scroll", margin: "5rem auto" }}>
            <table className="table caption-top text-end">
              <caption className="text-end">
                <h2 className="title">رحلات شهر شعبان</h2>
              </caption>
              <thead>
                <tr>
                  <th scope="col">بحر</th>
                  <th scope="col">بري</th>
                  <th scope="col">طيران</th>
                  <th scope="col">عدد الليالي</th>
                  <th scope="col">فنادق المدينة</th>
                  <th scope="col">المدينة</th>
                  <th scope="col">فنادق مكة</th>
                  <th scope="col">مكة</th>
                  <th scope="col">مستوة</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <TableUmrahTripsSchedule urlApi="services/umrah/tripsScheduleThree" />
            </table>
          </div>
          <div style={{ overflowX: "scroll", margin: "5rem auto" }}>
            <table className="table caption-top text-end">
              <caption className="text-end">
                <h2 className="title">رحلات شهر رمضان</h2>
              </caption>
              <thead>
                <tr>
                  <th scope="col">بحر</th>
                  <th scope="col">بري</th>
                  <th scope="col">طيران</th>
                  <th scope="col">عدد الليالي</th>
                  <th scope="col">فنادق المدينة</th>
                  <th scope="col">المدينة</th>
                  <th scope="col">فنادق مكة</th>
                  <th scope="col">مكة</th>
                  <th scope="col">مستوة</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <TableUmrahTripsSchedule urlApi="services/umrah/tripsScheduleFour" />
            </table>
          </div>
        </div>
      </div>

      <AdvancedIntro urlApi="services/umrah/advancedIntro" />
    </section>
  );
};

export default Umrah;
