import SimpleIntro from "../../components/General/SimpleIntro";
import AdvancedText from "../../components/General/AdvancedText";
import AdvancesCardList from "../../components/General/AdvancesCardList";
import TableForeignTourismTripsSchedule from "../../components/Tables/TableForeignTourismTripsSchedule";
import TableForeignTourismExtraOffer from "../../components/Tables/TableForeignTourismExtraOffers";
import AdvancedIntro from "../../components/General/AdvancedIntro";

const ForeignTourism = () => {
  return (
    <section>
      <SimpleIntro urlApi="services/foreignTourism/simpleIntro" />

      <div className="container text-center">
        <div style={{ margin: "7rem auto" }}>
          <AdvancedText urlApi="services/foreignTourism/advancedText" />

          <AdvancesCardList urlApi="services/foreignTourism/foreignTourismList" />
        </div>

        <div style={{ margin: "7rem auto" }}>
          <div style={{ overflowX: "scroll", margin: "5rem auto" }}>
            <table className="table caption-top text-end">
              <caption className="text-end">
                <h2 className="title">رحلات اسطنبول تركيا</h2>
              </caption>
              <thead>
                <tr>
                  <th scope="col">من 12:6 (سرير اطفال)</th>
                  <th scope="col">اطفال 6:2</th>
                  <th scope="col">اطفال 2:0</th>
                  <th scope="col">الفندق</th>
                  <th scope="col">ثلاثي</th>
                  <th scope="col">ثنائي</th>
                  <th scope="col">مفرد</th>
                  <th scope="col">الموقع</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <TableForeignTourismTripsSchedule urlApi="services/foreignTourism/tripsSchedule" />
            </table>
          </div>

          <div style={{ overflowX: "scroll" }}></div>
          <table className="table caption-top text-end">
            <thead>
              <tr>
                <th scope="col">اطفال 12:5</th>
                <th scope="col">اطفال 5:0</th>
                <th scope="col">تكلفة</th>
                <th scope="col">الرحلات الاضافية</th>
                <th scope="col">#</th>
              </tr>
            </thead>
            <TableForeignTourismExtraOffer urlApi="services/foreignTourism/extraOffers" />
          </table>
        </div>
      </div>

      <AdvancedIntro urlApi="services/foreignTourism/advancedIntro" />
    </section>
  );
};

export default ForeignTourism;
