import React, { useEffect, useState } from "react";
import Btn from "../General/Btn";

const Carousel = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://elgalatours-api.herokuapp.com/home/carousel")
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div
      id="carouselExampleFade"
      className="carousel slide carousel-fade"
      data-bs-interval="2500"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div
          className="carousel-item active"
          style={{
            background:
              "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),url('https://res.cloudinary.com/dirbnpgsp/image/upload/v1655340419/Elgala%20Tours/Pro_Photographer_Business_Facebook_Cover_qkjm5q.png')",
            height: "85vh",
            position: "relative",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        {items.map((item) => (
          <div
            key={item.id}
            className="carousel-item"
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),url(${item.img})`,
              height: "85vh",
              position: "relative",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div
              className="carousel-caption text-end"
              style={{
                position: "absolute",
                top: "38%",
                margin: "0 1.5%",
              }}
            >
              <h2
                className="fw-bolder mb-4"
                style={{ fontSize: "3rem", color: "#e8eaed" }}
              >
                {item.title}
              </h2>
              <Btn
                url={item.url}
                title={item.titleBtn}
                iconLeft="bi bi-arrow-left"
              />
            </div>
          </div>
        ))}
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
