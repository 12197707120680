const SimpleCard = (props) => {
  return (
    <div
      className="card border-0 my-3"
      style={{
        minHeight: "15.5rem",
        background: "var(--background_secondary)",
        transition: "var(--transition)",
      }}
    >
      <div className="card-body text-end">
        <i
          className={props.icon}
          style={{
            fontSize: "2.5rem",
            color: "var(--brand_secondary)",
          }}
        ></i>
        <h3 className="title">{props.title}</h3>
        <p className="subtitle">{props.subtitle}</p>
      </div>
    </div>
  );
};

export default SimpleCard;
