import SimpleIntro from "../components/General/SimpleIntro";
import AdvancedText from "../components/General/AdvancedText";
import AdvancesCardList from "../components/General/AdvancesCardList";
import Ads from "../components/General/Ads";
import AdvancedIntro from "../components/General/AdvancedIntro";

const Services = () => {
  return (
    <section>
      <SimpleIntro urlApi="services/simpleIntro" />

      <div className="container text-center" style={{ margin: "7rem auto" }}>
        <AdvancedText urlApi="services/advancedText" />

        <AdvancesCardList urlApi="services/servicesList" />
      </div>

      <Ads />

      <AdvancedIntro urlApi="services/advancedIntro" />
    </section>
  );
};

export default Services;
