import React, { useEffect, useState } from "react";

const FlightNumbers = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://elgalatours-api.herokuapp.com/about/flightNumbers")
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div
      className="text-center text-light"
      style={{ background: "var(--text_third)", margin: "7rem auto" }}
    >
      <div
        className="container"
        style={{
          paddingTop: "10rem",
          paddingBottom: "10rem",
        }}
      >
        <div className="mb-5">
          <p className="mb-0">معلومات عنا</p>
          <h1>العدد يتحدث بصوت أعلى</h1>
          <h2 className="fw-light">
            العدد التقريبي الي الرحلات التي قامت بها شركة الجالا تورز للسياحة
          </h2>
        </div>
        <div className="row">
          {items.map((item) => (
            <div
              key={item.id}
              className={`col-lg-3 col-md-6 col-sm-12 my-3 ${item.class}`}
            >
              <h1 className="mb-4">{item.title}</h1>
              <h5>{item.subtitle}</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlightNumbers;
