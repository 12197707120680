import React, { useEffect, useState } from "react";

const TableForeignTourismExtraOffers = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://elgalatours-api.herokuapp.com/${props.urlApi}`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <tbody>
      {items.map((item) => (
        <tr key={item.id}>
          <td>{item.Kids2}</td>
          <td>{item.kids1}</td>
          <td>{item.cost}</td>
          <td>{item.location}</td>
          <th scope="row">{item.id}</th>
        </tr>
      ))}
    </tbody>
  );
};

export default TableForeignTourismExtraOffers;
