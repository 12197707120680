import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useState, useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import "./styles/App.css";
import Header from "./components/App/Header";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import WhyUs from "./pages/WhyUs";
import Services from "./pages/Services";
import Pilgrimage from "./pages/ServicesPage/Pilgrimage";
import Umrah from "./pages/ServicesPage/Umrah";
import DomesticTourism from "./pages/ServicesPage/DomesticTourism";
import ForeignTourism from "./pages/ServicesPage/ForeignTourism";
import HoneymoonTrips from "./pages/ServicesPage/HoneymoonTrips";
import Visas from "./pages/ServicesPage/Visas";
import TouristTransportation from "./pages/ServicesPage/TouristTransportation";
import BookingFlight from "./pages/ServicesPage/BookingFlight";
import Vip from "./pages/ServicesPage/Vip";
import HotelBooking from "./pages/ServicesPage/HotelBooking";
import MedicalTourism from "./pages/ServicesPage/MedicalTourism";
import Testimonials from "./pages/Testimonials";
import Jobs from "./pages/Jobs";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/App/Footer";
import NotFound from "./pages/NotFound";

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <section>
      {loading ? (
        <div
          style={{
            maxWidth: "700px",
            minWidth: "300px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "2",
          }}
        >
          <BeatLoader color={"#EBA93F"} loading={loading} size={90} />
        </div>
      ) : (
        <Router>
          <Header />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>

            <Route path="/about" exact>
              <AboutUs />
            </Route>

            <Route path="/why" exact>
              <WhyUs />
            </Route>

            <Route path="/services" exact>
              <Services />
            </Route>

            <Route path="/services/pilgrimage" exact>
              <Pilgrimage />
            </Route>

            <Route path="/services/umrah" exact>
              <Umrah />
            </Route>

            <Route path="/services/domesticTourism" exact>
              <DomesticTourism />
            </Route>

            <Route path="/services/foreignTourism" exact>
              <ForeignTourism />
            </Route>

            <Route path="/services/honeymoonTrips" exact>
              <HoneymoonTrips />
            </Route>

            <Route path="/services/visas" exact>
              <Visas />
            </Route>

            <Route path="/services/touristTransportation" exact>
              <TouristTransportation />
            </Route>

            <Route path="/services/bookingFlight" exact>
              <BookingFlight />
            </Route>

            <Route path="/services/vip" exact>
              <Vip />
            </Route>

            <Route path="/services/hotelBooking" exact>
              <HotelBooking />
            </Route>

            <Route path="/services/medicalTourism" exact>
              <MedicalTourism />
            </Route>

            <Route path="/testimonials" exact>
              <Testimonials />
            </Route>

            <Route path="/jobs" exact>
              <Jobs />
            </Route>

            <Route path="/contact" exact>
              <ContactUs />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
          <Footer />
        </Router>
      )}
    </section>
  );
}

export default App;
