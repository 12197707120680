import SimpleIntro from "../../components/General/SimpleIntro";
import AdvancedText from "../../components/General/AdvancedText";
import VolunteeringBannerLeft from "../../components/General/VolunteeringBannerLeftImg";
import SpecialVolunteeringBannerRightImg from "../../components/General/SpecialVolunteeringBannerRightImg";
import AdvancedIntro from "../../components/General/AdvancedIntro";
import SimpleText from "../../components/General/SempleText";

const TouristTransportation = () => {
  return (
    <section>
      <SimpleIntro urlApi="services/touristTransportation/simpleIntro" />

      <div className="text-center" style={{ margin: "7rem auto" }}>
        <AdvancedText urlApi="services/touristTransportation/advancedText" />
      </div>

      <VolunteeringBannerLeft urlApi="services/touristTransportation/volunteeringBannerLeftImg" />

      <SpecialVolunteeringBannerRightImg urlApi="services/touristTransportation/touristTransportationVolunteeringBannerRightImg" />

      <div className="container text-center">
        <div style={{ margin: "7rem auto" }}>
          <div style={{ overflowX: "scroll", margin: "5rem auto" }}>
            <table className="table caption-top text-end">
              <caption className="text-end">
                <h2 className="title">
                  MERCEDES BENZ BUS اسعار و مواعيد
                  <br />
                  <br />
                  <i className="bi bi-check-square-fill mx-4 text-warning"></i>:
                  الاتوبيس الاول
                  <br />
                  <i className="bi bi-check-square-fill mx-4 text-primary"></i>:
                  الاتوبيس الثاني
                  <br />
                  <br />
                  الحد الادني الي السعر في شهر يونيو : 000
                </h2>
              </caption>
              <thead>
                <tr>
                  <th scope="col">الجمعة</th>
                  <th scope="col">الخميس</th>
                  <th scope="col">الاربعاء</th>
                  <th scope="col">الثلاثاء</th>
                  <th scope="col">الاثنين</th>
                  <th scope="col">الاحد</th>
                  <th scope="col">السبت</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>3</td>
                  <td>2</td>
                  <td>
                    <i className="bi bi-check-square-fill me-2 text-primary"></i>
                    <i className="bi bi-check-square-fill me-2 text-warning"></i>
                    1
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>9</td>
                  <td>8</td>
                  <td>7</td>
                  <td>6</td>
                  <td>5</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>16</td>
                  <td>15</td>
                  <td>14</td>
                  <td>13</td>
                  <td>12</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>23</td>
                  <td>22</td>
                  <td>21</td>
                  <td>20</td>
                  <td>19</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>30</td>
                  <td>29</td>
                  <td>28</td>
                  <td>27</td>
                  <td>26</td>
                  <td>25</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ overflowX: "scroll", margin: "5rem auto" }}>
            <table className="table caption-top text-end">
              <caption className="text-end">
                <h2 className="title">
                  TOYOTA HIACE MINIBUS اسعار و مواعيد
                  <br />
                  <br />
                  <i className="bi bi-check-square-fill mx-4 text-warning"></i>:
                  ميني باص الاول
                  <br />
                  <i className="bi bi-check-square-fill mx-4 text-primary"></i>:
                  ميني باص الثاني
                  <br />
                  <i className="bi bi-check-square-fill mx-4 text-success"></i>:
                  ميني باص الثالث
                  <br />
                  <br />
                  الحد الادني الي السعر في شهر يونيو : 000
                </h2>
              </caption>
              <thead>
                <tr>
                  <th scope="col">الجمعة</th>
                  <th scope="col">الخميس</th>
                  <th scope="col">الاربعاء</th>
                  <th scope="col">الثلاثاء</th>
                  <th scope="col">الاثنين</th>
                  <th scope="col">الاحد</th>
                  <th scope="col">السبت</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>3</td>
                  <td>2</td>
                  <td>
                    <i className="bi bi-check-square-fill me-2 text-success"></i>
                    <i className="bi bi-check-square-fill me-2 text-primary"></i>
                    <i className="bi bi-check-square-fill me-2 text-warning"></i>
                    1
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>9</td>
                  <td>8</td>
                  <td>7</td>
                  <td>6</td>
                  <td>5</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>16</td>
                  <td>15</td>
                  <td>14</td>
                  <td>13</td>
                  <td>12</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>23</td>
                  <td>22</td>
                  <td>21</td>
                  <td>20</td>
                  <td>19</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>30</td>
                  <td>29</td>
                  <td>28</td>
                  <td>27</td>
                  <td>26</td>
                  <td>25</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <SimpleText urlApi="services/touristTransportation/simpleText" />

      <AdvancedIntro urlApi="services/touristTransportation/advancedIntro" />
    </section>
  );
};

export default TouristTransportation;
