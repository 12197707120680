import React, { useEffect, useState } from "react";
import AdvancedText from "../General/AdvancedText";

const PhotoGallery = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://elgalatours-api.herokuapp.com/about/photoGallery")
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div className="container text-center" style={{ margin: "7rem auto" }}>
      <AdvancedText urlApi="about/photoGalleryAdvancedText" />

      <div className="row">
        {items.map((item) => (
          <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 my-3">
            <img
              src={item.img}
              className="card-img-top rounded"
              alt="Img Photo Gallery"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoGallery;
