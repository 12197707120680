import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section
      style={{
        paddingTop: "10rem",
        paddingBottom: "5rem",
      }}
    >
      <div className="text-center m-5">
        <h1>Sorry</h1>
        <p>That page cannot be found</p>
        <Link to="/">Back to the home page</Link>
      </div>
    </section>
  );
};

export default NotFound;
