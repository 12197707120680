import React, { useEffect, useState } from "react";
import AdvancedText from "../General/AdvancedText";

const WhyList = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://elgalatours-api.herokuapp.com/why/whyList")
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div className="container text-center" style={{ margin: "7rem auto" }}>
      <AdvancedText urlApi="why/whyListAdvancedText" />

      <div className="row" style={{ margin: "0 20%" }}>
        {items.map((item) => (
          <div key={item.id} className="col-md-6 col-sm-12 my-3 d-flex">
            <div className="text-end">
              <h4 className="title fw-normal mb-4">{item.title}</h4>
              <p className="subtitle">{item.subtitle}</p>
            </div>
            <div className="title ms-4">
              <i className={`${item.icon} fs-1`}></i>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyList;
