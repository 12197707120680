import SimpleIntro from "../components/General/SimpleIntro";
import AdvancedText from "../components/General/AdvancedText";
import AdvancedIntro from "../components/General/AdvancedIntro";

const ContactUs = () => {
  return (
    <section>
      <SimpleIntro urlApi="contact/simpleIntro" />

      <div className="container text-center">
        <div style={{ margin: "7rem auto" }}>
          <AdvancedText urlApi="contact/advancedText" />

          <form
            action="https://formsubmit.co/elgalatours.customers@gmail.com"
            method="POST"
          >
            <div className="row">
              <input
                type="hidden"
                name="_subject"
                value="رسايل صفحة تواصل معنا : رسايل"
              />
              <input
                type="hidden"
                name="_next"
                value="https://elgalatours.vercel.app/contact"
              />
              <div className="col-md-6 col-sm-12 my-2">
                <input
                  type="text"
                  name="Full Name"
                  placeholder="الاسم الكامل"
                  className="p-2"
                  style={{
                    border: "3px solid var(--text_third)",
                  }}
                  required
                />
              </div>
              <div className="col-md-6 col-sm-12 my-2">
                <input
                  type="email"
                  name="Email Address"
                  placeholder="عنوان بريد الكتروني"
                  className="p-2"
                  style={{
                    border: "3px solid var(--text_third)",
                  }}
                  required
                />
              </div>
              <div className="col-md-6 col-sm-12 my-2">
                <input
                  type="number"
                  name="Phone Number"
                  placeholder="رقم الهاتف"
                  className="p-2"
                  style={{
                    border: "3px solid var(--text_third)",
                  }}
                  required
                />
              </div>
              <div className="col-md-6 col-sm-12 my-2">
                <input
                  type="text"
                  name="Subject"
                  placeholder="موضوع"
                  className="p-2"
                  style={{
                    border: "3px solid var(--text_third)",
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <textarea
                  type="text"
                  name="Massage"
                  placeholder="رسالة"
                  className="px-2"
                  required
                  style={{
                    border: "3px solid var(--text_third)",
                    minHeight: "15rem",
                  }}
                />
              </div>
            </div>

            <button type="submit" className="btn" style={{ width: "100%" }}>
              Send Massage
            </button>
          </form>
        </div>

        <div className="row" style={{ margin: "7rem auto" }}>
          <div className="col-md-4 col-sm-12 my-3">
            <div className="mb-4">
              <i
                className="bi bi-geo-alt text-light py-2 px-4"
                style={{
                  fontSize: "2.5rem",
                  background: "var(--brand_secondary)",
                  borderRadius: "50px",
                }}
              ></i>
            </div>
            <h3 className="title mb-4">العنوان</h3>
            <p className="subtitle">
              3 ش صلاح سالم عمارات العبور مصر الجديدة شقة 7
            </p>
            <p className="subtitle">2 عمارة النيابة الادراية -ابوتيج -اسيوط</p>
          </div>
          <div className="col-md-4 col-sm-12 my-3">
            <div className="mb-4">
              <i
                className="bi bi-envelope text-light py-2 px-4"
                style={{
                  fontSize: "2.5rem",
                  background: "var(--brand_secondary)",
                  borderRadius: "50px",
                }}
              ></i>
            </div>
            <h3 className="title mb-4">الايميل</h3>
            <p>
              <a className="subtitle" href="mailto: sales@elgalatours.com">
                sales@elgalatours.com
              </a>
            </p>
          </div>
          <div className="col-md-4 col-sm-12 my-3">
            <div className="mb-4">
              <i
                className="bi bi-telephone text-light py-2 px-4"
                style={{
                  fontSize: "2.5rem",
                  background: "var(--brand_secondary)",
                  borderRadius: "50px",
                }}
              ></i>
            </div>
            <h3 className="title mb-4">الارقام</h3>
            <p>
              <a className="subtitle" href="tel:01156000980">
                0115-600-0980
              </a>{" "}
              ,{" "}
              <a className="subtitle" href="tel:01228020201">
                0122-802-0201
              </a>
            </p>
            <p className="subtitle">
              <a className="subtitle" href="tel:35703820">
                35703820
              </a>{" "}
              ,{" "}
              <a className="subtitle" href="tel:35703822">
                35703822
              </a>
            </p>
          </div>
        </div>
      </div>

      <AdvancedIntro urlApi="contact/advancedIntro" />
    </section>
  );
};

export default ContactUs;
