import SimpleIntro from "../components/General/SimpleIntro";
import AdvancedText from "../components/General/AdvancedText";
import SimpleCardsList from "../components/General/SimpleCardsList";

const Testimonials = () => {
  return (
    <section>
      <SimpleIntro urlApi="testimonials/simpleIntro" />

      <div className="container text-center" style={{ margin: "7rem auto" }}>
        <AdvancedText urlApi="testimonials/advancedText" />

        <SimpleCardsList urlApi="testimonials/testimonialsList" />
      </div>
    </section>
  );
};

export default Testimonials;
