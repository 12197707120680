import React, { useEffect, useState } from "react";

const AdvancedText = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://elgalatours-api.herokuapp.com/${props.urlApi}`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div>
      {items.map((item) => (
        <div key={item.id} className="mb-5">
          <p className="subtitle mb-0">{item.topSubtitle}</p>
          <h1 className="title">{item.title}</h1>
          <h2 className="subtitle fw-light">{item.subtitle}</h2>
        </div>
      ))}
    </div>
  );
};

export default AdvancedText;
