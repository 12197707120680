const Ads = () => {
  return (
    <div style={{ background: "var(--text_third)", margin: "7rem auto" }}>
      <div
        className="container text-center"
        style={{ paddingTop: "7rem", paddingBottom: "7rem" }}
      >
        <div className="mb-5 text-light">
          <p className="mb-0">تواصل معنا</p>
          <h1>الي افضل العروض</h1>
          <h2 className="fw-light">ارسل لنا رقمك لمعرفة احدث العروض</h2>
        </div>

        <form
          action="https://formsubmit.co/elgalatours.customers@gmail.com"
          method="POST"
        >
          <input
            type="hidden"
            name="_subject"
            value="رسايل صفحة الرئيسية : ارقام العملاء"
          />
          <input
            type="hidden"
            name="_next"
            value="https://elgalatours.vercel.app"
          />
          <div className="row">
            <div className="col-md-10 col-sm-12 my-2 ">
              <input
                type="number"
                name="Phone Number"
                placeholder="رقم الهاتف"
                className="p-2"
                style={{
                  border: "3px solid #fff",
                }}
                required
              />
            </div>
            <div className="col-md-2 col-sm-12 my-2 ">
              <button
                type="submit"
                className="btn btn-light"
                style={{ width: "100%" }}
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Ads;
