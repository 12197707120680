import React, { useEffect, useState } from "react";
import Btn from "../General/Btn";

const Offers = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://elgalatours-api.herokuapp.com/home/offers")
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div className="container text-center" style={{ margin: "7rem auto" }}>
      <div className="row justify-content-end">
        {items.map((item) => (
          <div key={item.id} className="col-lg-3 col-md-6 col-sm-12">
            <div
              className="card border-0 my-3"
              style={{
                background: "var(--background_secondary)",
                transition: "var(--transition)",
              }}
            >
              <h4 className="card-header title fw-bold py-3">{item.title}</h4>
              <img
                src={item.img}
                className="card-img-top"
                style={{ minHeight: "15rem" }}
                alt="Offers Cards Img"
              />
              <div className="card-body">
                <p className="subtitle">{item.subtitle}</p>
                <Btn
                  url={item.url}
                  title="المزيد"
                  iconLeft="bi bi-arrow-left"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Offers;
