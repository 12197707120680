import SimpleIntro from "../components/General/SimpleIntro";
import AdvancedText from "../components/General/AdvancedText";
import AdvancedIntro from "../components/General/AdvancedIntro";

const Jobs = () => {
  return (
    <section>
      <SimpleIntro urlApi="jobs/simpleIntro" />

      <div className="container text-center" style={{ margin: "7rem auto" }}>
        <AdvancedText urlApi="jobs/advancedText" />

        <h1 className="title py-5">لا يوجد الان</h1>
      </div>

      <AdvancedIntro urlApi="jobs/advancedIntro" />
    </section>
  );
};

export default Jobs;
