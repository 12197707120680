import SimpleIntro from "../../components/General/SimpleIntro";
import AdvancedText from "../../components/General/AdvancedText";
import AdvancesCardList from "../../components/General/AdvancesCardList";
import AdvancedIntro from "../../components/General/AdvancedIntro";

const DomesticTourism = () => {
  return (
    <section>
      <SimpleIntro urlApi="services/domesticTourism/simpleIntro" />

      <div className="container text-center">
        <div style={{ margin: "7rem auto" }}>
          <AdvancedText urlApi="services/domesticTourism/advancedText" />

          <AdvancesCardList urlApi="services/domesticTourism/domesticTourismList" />
        </div>
      </div>

      <AdvancedIntro urlApi="services/domesticTourism/advancedIntro" />
    </section>
  );
};

export default DomesticTourism;
