import Carousel from "../components/Home/Carousel";
import Offers from "../components/Home/Offers";
import VolunteeringBannerLeft from "../components/General/VolunteeringBannerLeftImg";
import SimpleCardsList from "../components/General/SimpleCardsList";
import Services from "../components/Home/Services";
import Ads from "../components/General/Ads";
import VolunteeringBannerRight from "../components/General/VolunteeringBannerRightImg";
import AdvancedIntro from "../components/General/AdvancedIntro";

const Home = () => {
  return (
    <section>
      <Carousel />

      <Offers />

      <VolunteeringBannerLeft urlApi="home/volunteeringBannerLeftImg" />

      <SimpleCardsList urlApi="home/cards" />

      <Services />

      <Ads />

      <VolunteeringBannerRight urlApi="home/volunteeringBannerRightImg" />

      <AdvancedIntro urlApi="home/advancedIntro" />
    </section>
  );
};

export default Home;
