import { Link } from "react-router-dom";

const Btn = (props) => {
  return (
    <Link to={props.url}>
      <h5 className="btn">
        <i className={props.iconLeft}></i>
        {props.title}
      </h5>
    </Link>
  );
};

export default Btn;
