import SimpleIntro from "../components/General/SimpleIntro";
import VolunteeringBannerRight from "../components/General/VolunteeringBannerRightImg";
import VolunteeringBannerLeft from "../components/General/VolunteeringBannerLeftImg";
import WhyList from "../components/Why/WhyList";
import SpecialVolunteeringBannerRightImg from "../components/General/SpecialVolunteeringBannerRightImg";

const WhyUs = () => {
  return (
    <section>
      <SimpleIntro urlApi="why/simpleIntro" />

      <VolunteeringBannerRight urlApi="why/volunteeringBannerRightImg" />

      <VolunteeringBannerLeft urlApi="why/volunteeringBannerLeftImg" />

      <WhyList />

      <SpecialVolunteeringBannerRightImg urlApi="why/whyVolunteeringBannerRightImg" />
    </section>
  );
};

export default WhyUs;
