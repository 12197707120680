import React, { useEffect, useState } from "react";
import AdvancedCard from "./AdvancedCard";

const AdvancesCardList = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`https://elgalatours-api.herokuapp.com/${props.urlApi}`)
      .then((res) => res.json())
      .then((data) => setItems(data));
  });

  return (
    <div className="row">
      {items.map((item) => (
        <div key={item.id} className="col-lg-4 col-md-6 col-sm-12">
          <AdvancedCard
            url={item.url}
            img={item.img}
            title={item.title}
            subtitle={item.subtitle}
          />
        </div>
      ))}
    </div>
  );
};

export default AdvancesCardList;
